import '@fortawesome/fontawesome-free/js/all';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import logoColor from '../assets/header/logo/modo-logo-color@2x.png';
import logoWhite from '../assets/header/logo/modo-logo-white@2x.png';
import EmailForm from './email-form';
import headerStyles from './header-home.module.scss';
import MenuItems from './menu-items';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulMenu {
        title
        careerMessage
        chatLink
        contactInfo {
          phoneNumber
          careerEmail
          infoEmail
          facebook
          linkedIn
          pinterest
          twitter
        }
      }
    }
  `);

  const [open, setOpen] = useState(false);

  const switchNav = () => {
    setOpen(!open);
    trackCustomEvent({
      category: 'Navigation',
      action: 'Click',
      label: open ? 'Open Menu' : 'Close Menu',
      value: 43,
    });
  };

  return (
    <header className={headerStyles.headerHome}>
      <div
        className={open ? headerStyles.overlayOpen : headerStyles.overlayClose}
      >
        <Grid className={headerStyles.menuContents}>
          <div className={headerStyles.bar}>
            <Row>
              <Col xs={6}>
                <Link onClick={() => switchNav()} to={'/'}>
                  <img
                    className={headerStyles.logo}
                    src={logoWhite}
                    alt="modo"
                  />
                </Link>
              </Col>
              <Col xs={6}>
                <div onClick={() => switchNav()} className={headerStyles.menu}>
                  <div className={headerStyles.icon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.414"
                      height="31.414"
                      viewBox="0 0 31.414 31.414"
                    >
                      <g
                        id="Group_260"
                        data-name="Group 260"
                        transform="translate(-1688.293 -59.293)"
                      >
                        <line
                          id="Line_118"
                          data-name="Line 118"
                          x2="30"
                          y2="30"
                          transform="translate(1689 60)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_119"
                          data-name="Line 119"
                          x1="30"
                          y2="30"
                          transform="translate(1689 60)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className={headerStyles.menuContainer}>
            <div className={headerStyles.menuContain}>
              <Row className={headerStyles.menuWrap}>
                <Col sm={12} md={5}>
                  <div onClick={() => switchNav()}>
                    <MenuItems />
                  </div>
                </Col>
                <Col sm={12} md={7}>
                  <h1 className="squish">{data.contentfulMenu.title}</h1>
                  <EmailForm />
                </Col>
              </Row>
              <Row className={headerStyles.menuFooter}>
                <Col sm={12} md={5}>
                  <Row className="noPadTop">
                    <Col xs={6} md={12}>
                      <ul className={headerStyles.contactLinks}>
                        <li>
                          <OutboundLink
                            href={`tel:+${data.contentfulMenu.contactInfo.phoneNumber}`}
                          >
                            {data.contentfulMenu.contactInfo.phoneNumber}
                          </OutboundLink>
                        </li>
                        <li>
                          <OutboundLink
                            href={`mailto:${data.contentfulMenu.contactInfo.infoEmail}`}
                          >
                            <i>{data.contentfulMenu.contactInfo.infoEmail}</i>
                          </OutboundLink>
                        </li>
                      </ul>
                    </Col>
                    <Col xs={6} md={12}>
                      <div className={headerStyles.socialLinks}>
                        <ul className={headerStyles.buttons}>
                          <li>
                            <OutboundLink
                              href={data.contentfulMenu.contactInfo.linkedIn}
                              target="_blank"
                            >
                              <i className="fab  fa-linkedin"></i>
                            </OutboundLink>
                          </li>
                          <li>
                            <OutboundLink
                              href={data.contentfulMenu.contactInfo.twitter}
                              target="_blank"
                            >
                              <i className="fab  fa-twitter"></i>
                            </OutboundLink>
                          </li>
                          <li>
                            <OutboundLink
                              href={data.contentfulMenu.contactInfo.facebook}
                              target="_blank"
                            >
                              <i className="fab  fa-facebook-f"></i>
                            </OutboundLink>
                          </li>
                          <li>
                            <OutboundLink
                              href={data.contentfulMenu.contactInfo.pinterest}
                              target="_blank"
                            >
                              <i className="fab  fa-pinterest"></i>
                            </OutboundLink>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={6} className="mHide">
                  <p className={headerStyles.career}>
                    {data.contentfulMenu.careerMessage}{' '}
                    <span>
                      <Link to={`/contact`}>
                        {data.contentfulMenu.chatLink}
                      </Link>
                    </span>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Grid>
      </div>
      <Grid className={headerStyles.headerWrap}>
        <Row className={headerStyles.row}>
          <Col md={12} className={headerStyles.barWrap}>
            <Link to={'/'}>
              <img className={headerStyles.logo} src={logoWhite} alt="Modo" />
            </Link>
            <div onClick={() => switchNav()} className={headerStyles.menu}>
              <div className={headerStyles.icon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="31"
                  viewBox="0 0 41 31"
                >
                  <g
                    id="Group_256"
                    data-name="Group 256"
                    transform="translate(-1725 -42.5)"
                  >
                    <line
                      id="Line_51"
                      data-name="Line 51"
                      x2="41"
                      transform="translate(1725 44)"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth="3"
                    />
                    <line
                      id="Line_52"
                      data-name="Line 52"
                      x2="41"
                      transform="translate(1725 58)"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth="3"
                    />
                    <line
                      id="Line_53"
                      data-name="Line 53"
                      x2="41"
                      transform="translate(1725 72)"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth="3"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </Col>
        </Row>
        <div className={headerStyles.bg}></div>
      </Grid>
    </header>
  );
};

export default Header;
