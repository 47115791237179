import React, { useRef, useEffect } from 'react';
import Footer from '../components/footer';
import HeaderHome from '../components/header-home';
import Header from '../components/header';
import '../styles/index.scss';

const LayoutHome = (props: any) => {
  const ref = useRef()

  useEffect(() => {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype && ref.current
    ) {
      let observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
          document.body.classList.add("scrolled");
        } else {
          document.body.classList.remove("scrolled");
        }
      });
    
      observer.observe(ref.current);
    }
  }, [ref.current])

  return (
    <div className="home-wrap">
      <HeaderHome />
      <Header />
      <div ref={ref} id="marker" />
      {props.children}
      <Footer />
    </div>
  );
};

export default LayoutHome;
