import { graphql, useStaticQuery, Link } from 'gatsby';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Contact from '../components/contact';
import LayoutHome from '../components/layout-home';
import ProductCarousel from '../components/product-carousel';
import homeStyles from './home.module.scss';
import Head from '../components/head';
import { useInView } from 'react-intersection-observer';

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHome {
        section1Message
        section1Title
        section2Button
        section2List
        section2Message
        section2Title
        section3Message
        section3Title
        section1Image {
          file {
            fileName
            url
          }
        }
        section2Image {
          file {
            fileName
            url
          }
        }
        logos {
          file {
            url
            fileName
          }
        }
        clientLogosMobile {
          file {
            url
            fileName
          }
        }
      }
    }
  `);

  const options = { threshold: 0.5, triggerOnce: true };
  const optionsHead = { threshold: 1, triggerOnce: true };
  
  const [section1TitleRef, section1TitleInView, section1TitleEntry] = useInView(
    options,
  );
  const [section2TitleRef, section2TitleInView, section2TitleEntry] = useInView(
    options,
  );
  const [headerbgRef, headerbgInView, headerbgEntry] = useInView(
    optionsHead,
  );
  const [section1ImageRef, section1ImageInView, section1ImageEntry] = useInView(
    options,
  );
  const [
    section2MessageRef,
    section2MessageInView,
    section2MessageEntry,
  ] = useInView(options);
  const [section2ImageRef, section2ImageInView, section2ImageEntry] = useInView(
    options,
  );
  const [section3TitleRef, section3TitleInView, section3TitleEntry] = useInView(
    options,
  );
  const [
    section3MessageRef,
    section3MessageInView,
    section3MessageEntry,
  ] = useInView(options);
  const [listNavRef, listNavInView, listNavEntry] = useInView(options);
  const [buttonRef, buttonInView, buttonEntry] = useInView(options);

  return (
    <div>
      <Head title={'Home'} />
      <LayoutHome>
        <Grid className="header-background home-head vert-center">
          <Row>
            <Col xs={12} md={7} xl={6}>
              <div ref={section1TitleRef}>
                <h1
                  className={
                    section1TitleInView
                      ? homeStyles.slideUShow
                      : homeStyles.slideUHide
                  }
                >
                  {data.contentfulHome.section1Title}
                </h1>
                <h3
                  className={
                    section1TitleInView
                      ? homeStyles.slideUShow
                      : homeStyles.slideUHide
                  }
                >
                  {data.contentfulHome.section1Message}
                </h3>
              </div>
            </Col>
          </Row>

          <div className="overlay" />
          
          <div ref={headerbgRef}>
            <img className={`${headerbgInView ? homeStyles.fadeShow : homeStyles.fadeHide} bg`} src={data.contentfulHome.section1Image.file.url} />       
          </div>
        </Grid>

        <Grid className="grey inset vert-center">
          <Row>
            <Col xs={12} md={5}>
              <h1
                ref={section2TitleRef}
                className={
                  section2TitleInView
                    ? homeStyles.fadeShow
                    : homeStyles.fadeHide
                }
              >
                {data.contentfulHome.section2Title}
              </h1>
            </Col>
            <Col xs={12} md={6} mdOffset={1}>
              <h3
                ref={section2MessageRef}
                className={
                  section2MessageInView
                    ? homeStyles.fadeShow
                    : homeStyles.fadeHide
                }
              >
                {data.contentfulHome.section2Message}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3} className="alignTop">
              <ul className={homeStyles.listnav}>
                {data.contentfulHome.section2List.map(
                  (process: any, index: number) => (
                    <li
                      key={process}
                      ref={listNavRef}
                      className={
                        listNavInView
                          ? homeStyles.slideUShow
                          : homeStyles.slideUHide
                      }
                    >
                      <Link to={`/section/process#${process}`}>
                        <span className={homeStyles.listNumber}>{`0${index + 1}`}</span> <span className={homeStyles.listName}>{process}</span>
                      </Link>
                    </li>
                  ),
                )}
              </ul>

              <div ref={buttonRef}>
                <Link
                  to={'/section/process'}
                  className={`${
                    buttonInView ? homeStyles.fadeShow : homeStyles.fadeHide
                  } btn arrow`}
                >
                  {data.contentfulHome.section2Button}
                </Link>
              </div>
            </Col>
            <Col xs={12} md={9}>
              <div ref={section2ImageRef}>
                <img
                  className={`${
                    section2ImageInView
                      ? homeStyles.slideLShow
                      : homeStyles.slideLHide
                  } edge-right`}
                  src={data.contentfulHome.section2Image.file.url}
                  alt={data.contentfulHome.section2Image.file.fileName}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row className="addPadTop">
            <Col xs={12} md={5}>
              <h1
                ref={section3TitleRef}
                className={
                  section3TitleInView
                    ? homeStyles.fadeShow
                    : homeStyles.fadeHide
                }
              >
                {data.contentfulHome.section3Title}
              </h1>
            </Col>
            <Col xs={12} md={6} mdOffset={1}>
              <h3
                ref={section3MessageRef}
                className={
                  section3MessageInView
                    ? homeStyles.fadeShow
                    : homeStyles.fadeHide
                }
              >
                {data.contentfulHome.section3Message}
              </h3>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col md={12} className={homeStyles.logos}>
              <img className="mHide"
                src={data.contentfulHome.logos.file.url}
                alt={data.contentfulHome.logos.file.fileName}
              />

              <img className="dHide"
                src={data.contentfulHome.clientLogosMobile.file.url}
                alt={data.contentfulHome.clientLogosMobile.file.fileName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ProductCarousel />
            </Col>
          </Row>
        </Grid>
        <div className="grey">
          <Contact />
        </div>
      </LayoutHome>
    </div>
  );
};

export default Home;
