import { graphql, useStaticQuery, Link } from 'gatsby';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import contactStyles from './contact.module.scss';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { useInView } from 'react-intersection-observer';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulContact {
        contactTitle
        contactMessage
        contactButton
        contactInfo {
          infoEmail
        }
        showImage
        photo {
          file {
            fileName
            url
          }
        }
      }
    }
  `);

  const options = { threshold: 1, triggerOnce: true };
  const [titleRef, titleInView, titleEntry] = useInView(options);
  const [messageRef, messageInView, messageEntry] = useInView(options);
  const [buttonRef, buttonInView, buttonEntry] = useInView(options);
  const [symbolRef, symbolInView, symbolEntry] = useInView(options);
  const [imageRef, imageInView, imageEntry] = useInView(options);

  return (
    <Grid className={contactStyles.contactWrap}>
      <Row>
        <Col md={12} lg={7} className={contactStyles.contact}>
          <div
            ref={symbolRef}
            className={
              symbolInView ? contactStyles.fadeShow : contactStyles.fadeHide
            }
          >
            <div className={contactStyles.symbol}>
              <span />
            </div>
          </div>

          <div className={contactStyles.quotewrap}>
            <h1
              ref={titleRef}
              className={
                titleInView ? contactStyles.fadeShow : contactStyles.fadeHide
              }
            >
              {data.contentfulContact.contactTitle}
            </h1>
            <h4
              ref={messageRef}
              className={
                messageInView ? contactStyles.fadeShow : contactStyles.fadeHide
              }
            >
              {data.contentfulContact.contactMessage}
            </h4>
            <div
              ref={buttonRef}
              className={
                buttonInView ? contactStyles.fadeShow : contactStyles.fadeHide
              }
            >
              <Link to={`/contact`} className="btn arrow">
                {data.contentfulContact.contactButton}
              </Link>
            </div>
          </div>
        </Col>
        {data.contentfulContact.showImage && (
          <Col lg={5} className={contactStyles.contactImage}>
            <div ref={imageRef} className={imageInView ? contactStyles.fadeShow : contactStyles.fadeHide}>
              <div className="croppedImage">
                <img
                  className={contactStyles.getInTouchImage}
                  src={data.contentfulContact.photo.file.url}
                  alt={data.contentfulContact.photo.file.fileName} 
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Grid>
  );
};

export default Contact;
